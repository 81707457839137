<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-03-25 15:16:43
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-04-06 14:49:41
 * @FilePath: /chat_gpt/src/layout/mainUser.vue
-->
<template>
  <div class="content">
    <div class="topTitle">
              <!-- <img :src="logoIcon" alt="" style="width:25px;height:25px;margin-right:10px"> -->
              <span>项目孵化线上管理平台</span>
    </div>
    <div class="contentBox">
      <aside class="leftSide">
        <el-menu style="height:100%;width:220px"   :default-active="$route.path"
          class="el-menu-vertical-demo"   background-color="#012641"
          text-color="#fff" router  active-text-color="#F3A503">
          <div v-for="(part,index) in $router.options.routes"  :key="index">
            <div v-if="!part.hidden">
              <div v-for="(child, childIndex) in part.children" :key="childIndex" >
                <div v-if="child.role.indexOf(roleId)>-1 " @click="handleClick(child)">
                    <el-menu-item  v-if="!child.isChild" :index="child.path" >
                      <i :class="child.icon"></i>
                      <span slot="title">{{child.name}}</span>
                    </el-menu-item>
                    <el-submenu  v-if="child.isChild" :index="child.path">
                      <template slot="title">
                        <i :class="child.icon"></i>
                        <span slot="title">{{child.name}}</span>
                      </template>
                      <el-menu-item  v-for="son in child.children" :index="son.path" :key="son.path">{{son.name}}</el-menu-item>
                    </el-submenu>
                </div>
              </div>
            </div>
          </div>
        </el-menu>
        
        
        <div  class="bottom">
          <el-button @click="logOut" class="bottomBtn" type='text'>
            <i class="el-icon-user bottomIcon"></i>退出
          </el-button>
        </div>
      </aside>
      <div  class="rightSide">
        <!-- <keep-alive> -->
        <router-view v-if="isRouterAlive"></router-view>
      <!-- </keep-alive> -->
      </div>
    </div>
    
  </div>
</template>

<script>

import LayOut from './layOut.vue'
export default {
  components: { LayOut },
  data() {
    return {
      show: false,
      roleId:2,
      isRouterAlive: true,
    }
  },
  mounted() {
    // console.log(this.$route)
    // console.log(this.$router)
    this.roleId = JSON.parse( window.localStorage.getItem('userInfo')).roleId
  },
  methods: {
    toggle() {
      this.show = !this.show
      this.$store.commit('SET_OPEN', this.show)
    },
    handleClick(item){
      console.log('点击')
      console.log(item)
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },
    //退出
    logOut() {
      this.$httpsGET('LOGOUT',{}).then(res => {
         window.localStorage.clear()
         window.sessionStorage.clear()
        this.$message.success('正在退出登录')
        setTimeout(() => {
          this.$router.push('/login')
        }, 2000)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.content {
  background: #fff;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  .topTitle{
    height: 56px;
    line-height: 56px;
    padding:0 20px;
    width:100%;
    position: relative;
    font-size: 18px;
    color: rgb(243, 165, 3);
    font-weight: bold; 
    display: flex;
    align-items: center;
   
  }
  .contentBox{
    flex: 1 1;
    height: calc( 100vh - 56px );
    display: flex;
    align-items: center;
    .leftSide{
      height:100%;
      width:220px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      background: #012641;
      .bottom{
        border-top:1px solid rgb(222, 205, 205);
        padding: 8px 10px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        .bottomBtn{
          width:100%;
          text-align: left;
          color:#fff;
          // font-weight: 300;

        }
        .bottomIcon{
          font-size: 16px;
          margin-right: 10px;
        }
      }
    }
    .rightSide{
      flex: 1 1;
      height: 100%;
      overflow: hidden;
      background: #eee;
    box-shadow: 0 4px 10px 0 #DBDBDB inset;

    }
  }
 
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    background: #ffffff;
    .icon {
      margin-left: 20px;
      width: 20px;
    }
    .center {
      width: calc(100% - 110px);
      text-align: left;
    }
    .rights {
      width: 40px;
      margin-right: 10px;
    }
  }
}
</style>