/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-03-20 20:49:33
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-04-06 14:23:03
 * @FilePath: /chat_gpt/src/main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/css/style.scss'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import 'element-ui/lib/theme-chalk/index.css'
import i18n from './i8n'
import { REQUEST } from '@/api/http'
import * as echarts from 'echarts'



import VueClipboard from 'vue-clipboard2';//引入复制功能插件

import VueTouch from 'vue-touch'  //引用移动端图片手指滑动

Vue.use(VueTouch, {name:'v-touch'})


Vue.use(ElementUI,
  {
    i18n:(key, value) => i18n.t(key, value)  //在注册Element 时设置i18n的处理方法，可以实现当点击切换按钮后，elementUi可以自动切换.js语言文件实现多语言切换
  }
)
Vue.use(VueClipboard)

Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.prototype.$https = REQUEST.POST
Vue.prototype.$httpsGET = REQUEST.GET



new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
