export default {
    msg:{
        msg1:'测试一',
        inputHold:'请输入网址如：https://scholar.google.com/citations?user=U9gKp38AAAAJ',

    },
    headerModule:{
        mainTitle:'PaperSpotlight',
        subTitle:'Every Idea Matters, Every Paper Counts',
        navList:[
            // {name:'主页',className:'navItem',path:'/homePage',id:''},
            {name:'费用表',className:'navItem',path:'',id:'chargeBox'},
            {name:'联系我们',className:'navItem',path:'',id:'contactUs'},
            {name:'FAQ',className:'navItem',path:'',id:'FAQ'},
            // {name:'lang',className:'lang',path:'',id:''},
            {name:'登录',className:'navBottomLogin',path:'/login',id:''},
            {name:'注册',className:'navBottomReg',path:'/register',id:''},
        ],
        navListLog:[
            {name:'email',className:'navBottomLogin',path:'/userInfor',id:''},
            {name:'登出',className:'navBottomReg',path:'/loginOut',id:'',},
        ],
    },
    navigate:{
        flowObj0:[
            {mainTitle:'Status',subTitle:[
                {name:'需要做的',index:'0-0',path:'/status'},
            ]},
        ],
        flowObj1:[
            {mainTitle:'我的信息',subTitle:[
                {name:'基本信息',index:'1-0',path:'/userInfor'},
                {name:'账号信息',index:'1-1',path:'/account'},
            ]},
            {mainTitle:'套餐设置',subTitle:[
                {name:'创建新文章',index:'2-0',path:'/article'},
                {name:'套餐与文章关联',index:'2-1',path:'/package'},
                {name:'推送喜好',index:'2-2',path:'/pushPrefer'},

            ]}, 
        ],
        flowObj2:[
            {mainTitle:'推进状态',subTitle:[
                {name:'仪表板',index:'3-0',path:'/instrumentPanel'},
                {name:'触及的研究人员概括',index:'3-1',path:'/personnerProfile'},
            ]},
        ],
    },
    basicInfor:{
        firstTitile:'研究领域和专业信息',
        firstSubTiitle:'google scholar 个人网页',
        firstBtn:'确认及分析',
        secondTitle:'服务偏好', //
        secondSubTitle:'是否允许我们搜索你其他的学术背景信息',//
        allow:'允许',//
        notAllow:'不允许',//
        thirdTitle:'邮箱授权' ,
        thirdTitle2:'已授权邮箱',
        tips1:'我们非常注重隐私。这个姓名将用于访问发邮件时候的署名。',
        tips2:'如果选择不用自己的邮箱发送。那么将由我们代为推广。',
        thirdChoose1:'通过你的邮箱发送邮件',
        thirdChoose2:'使用paperSpotlight邮箱',
        fourTitle:'邀请码',
        fourBtn:'确认',
        btnText1:'保存',
        resetBtn:'重置',
        copyBtn:'复制',
        cancelBtn:'注销',
        thirdmark:'双方都赠送20封邮件发送及分析',
        personInfor:[
            {title:'姓名',value:'scholarName'},
            {title:'所属单位',value:'scholarAffiliation'},
            {title:'引用篇数',value:'citedby'},
            {title:'查询时间',value:'time'},
         ],
         editPersonInfor:{
            title:'修改',
            oldName:'原',
            newName:'新',
         }
    },
    articlModule:{
        articleTitle:'我创建的文章', 
        addBtn:'添加文章',
        formLable:{
            website:'文章网址',
            title:'文章标题',
            forwardKey:'正向关键词',
            inverseKey:'反向关键词',
            operation:'操作',
            editBtn:'修改',
            deleteBtn:'删除',
            link:'DOI，谷歌学术链接',
            placeholder:'输入后回车',
            pdfLable:'上传PDF',
            datasets:'数据 URL',
            github:'Github URL',
            celbtn:'取消',
            createbtn:'创建',
            confirmBtn:'确认',
        },
        createTitle:'创建文章',
        editTitele:'修改文章',
        deltitle:'是否删除该文章',
    },
    packageModule:{
        buytime:'购买时间',
        buyText:'订购 7 天后，我们不提供退款。',
        addArticle:'添加文章',
        celPackage:'取消套餐',
        pushArticle1:'当前推送文章',
        pushArticle2:'篇',
        buyNew:'购买新套餐',
        buyRecord:'购买记录',
        packageAndpaper:'套餐与文章关联',
        effect:'生效中',
        loseActive:'已失效',
        expired:'已失效',
        subActive:'订阅中',
        subFail:'已取消',
        expirse:'到期',
        cancelSub:'取消订阅',
        cancelSubed:'已取消订阅',
        refund:'退款',
        refunded:'已退款',
        packageDelTitle:'是否删除该文章',

    },
    pushModule:{
        title:'推送喜好',
        oneText1:'根据相关领域新文章分析, 关联性分析, 引用建议, 分析。这个会影响优先触达哪些论文作者. 如果您的购买量小的话',
        oneText3:'如: 时间重要性高于相关性, 那么会在最近的文章中, 找到一篇与您的文章相关性高的论文, 来优先发送, 而不是选择一篇5年前的相关性更高的论文发送。',
        importance:'重要性：',
        importtanceArr:[
            {name:'时间（越新的文章权重越高）'},
            {name:'您的论文与作者论文之间的相关性 (相关性高)'},
            {name:'引用量 (引用量高)'},
            {name:'大学排名 (大学排名高)'},
        ],
        allowText1:'允许不考虑以下的相关性：',
        allowText2:'如: 方法和Application都可以适用, 提供Interdisciplinary',

        twoResearch:'Researchers',
        twoText1:'是否对最新发表文章的作者, 进行邮箱介绍?',
        twoText2:'以下条件适用于同一人。例如。(对于会议论文，所有在美国大学攻读博士学位的中国作者都将收到您的电子邮件）。',
        twoText3:'如果还有发送量的话, 是否允许发送给相关性我们认为不那么高的论文? ：',
        allow:'允许',
        notAllow:'不允许',

        threeText1:'是否在相关领域社交平台上进行曝光 (仅曝光给: 勾选人员. 学生, 博士, 老师, 研究所)',

        fourText1:'是否有开源代码或数据集需要我们推广?',
        address:'地址：',

        fiveTitle:'Suggested by reviewer',
        fiveText1:'是否需要推送给我们的reviewers 和 editors? (以提高他们审文章时候要求添加的citation)',

        sixText1:'通过我们的分析, 针对性推荐给近期可能引用您文章的作者 ?',
        sixText2:'是否让我们的Editor与其他研究人员提出一些合作的可能性, 这代表着其他人对你的论文感兴趣并打算建立合作?',

        sevenText1:'是否需要做Reviewer? 如有合适的, 我们会进行推荐?',
        sevenText2:'Invitation to Join Our Panel of Expert Reviewers',

        eightText1:'邮箱或机构排除',
        eightText2:'（使用“，”排除电子邮件，例如您的实验室或顾问等）',
        nineText1:'已经发过邮件的人, 不再重复发送',
        tenText1:'通过我们机构匿名发送 (可能会降低Citation效果)',

        saveBtn:'保存设置',
    },
    panelModule:{
        country:'发送的国家',
        emailStatus:'邮件情况',
        totalArticle:'总发送篇数',
        upUntil:'截至目前',
        perDay:'每日发送数',
        totalSender:'总发送',
        receive:'收到回复',
        quoted:'被引用',
        receiveNum:'收到回复数',
        citationsNum:'引用篇数',
        data:[
            { value: 40, name: '总发送' },
            { value: 38, name: '收到回复' },
            { value: 32, name: '被引用' },
        ]
    },

    mainModule:{
        firstTitle1:'亲爱的博士生们、',
        firstTitle2:'研究人员、',
        firstTitle3:'这是给你们的！',
        contentList:[
            {text:"如果您正面临类似的挑战，这听起来一定很熟悉：",className:'headerItem'},
            {text:[
                '申请 NIW 需要 50-70 篇引文',
                '申请 EB1A 需要 120-240 条引文',
                '申请助理教授需要 150 - 800 篇引文',
                '确保资金',
                '职业发展',
                '获得风险投资杠杆',
            ],className:'textItem'},
            {text:"你并不孤单。<br/>  每年有数十万名应届毕业生 <br/> 遇到以下情况：",className:'headerItem'},
            {
                text:[
                    '引文有限/需要更多引文',
                    '引文增长不理想',
                    '惧怕可能给个人声誉带来任何负面影响的行为',
                    '不熟悉有效提高科学声誉的方法',
                    '没有时间或精力专注于宣传自己的论文',
                    '研究和项目应接不暇',
                    '建立联系和参加会议需要花费太多的时间、精力和金钱。',
                    '申请和文书工作也让人应接不暇。',
                    '希望引文数量能自行增长。'
                ],className:'textItem'
            },
            {text:'我们理解这些挑战，并将帮助您高效、有效地提高专业研究声誉。 ',className:'headerNormalItem'},
            {text:'这样，你就可以继续专注于你最擅长的领域、 <Br/>我们就能专注于我们最擅长的领域。',className:'headerItem'},
            {text:'请允许我们为您提供帮助。',className:'headerNormalItem'},
            {text:'您的作品，我们的焦点--纸张无小事。',className:'headerItem'},
            {text:'聚焦我的论文',className:'btnItem'},
            {text:'* 所需的引用次数可能取决于研究领域。',className:'markItem'},
        ],
        autoBtn:'自动续费',
        permonthBtn:'按月购买',
        consult:'咨询',
        email:'邮箱',
        conHolder1:'请输入内容',
        conHolder2:'请输入内容',
        toSay:'想说的',
        constactBtn:'联系',
        collapseObj:[
            {titel:'您的服务内容是什么？',content:'我们的服务通过有针对性的推广和战略建议，帮助研究人员提高其学术论文的知名度和引用率。'},
            {titel:'这项服务如何运作？',content:'用户在我们的平台上注册，选择服务计划，提交文章信息，剩下的就交给我们处理。这包括文章分析、有针对性的电子邮件活动、社交媒体推广等。'},
            {titel:'谁能从你们的服务中受益？',content:'研究人员、学者和学生如果希望提高其发表作品的知名度和影响力，可以从我们的服务中获益。'},
            {titel:'您推广哪些类型的文章？',content:'我们推广经同行评审的期刊文章、会议论文和其他跨学科的学术出版物。'},
            {titel:'如何分析文章？',content:'我们结合使用自动工具和专家评审，对文章的内容、相关性和影响力进行分析，并提供有针对性的引用建议。'},
            {titel:'您使用哪些平台进行宣传？',content:'我们利用学术社交网络、相关论坛、电子邮件营销活动和其他在线平台，确保您的文章能够送达正确的受众。'},
            {titel:'你们提供哪些不同的服务计划？',content:'我们提供五种计划： 基本计划、标准计划、高级计划、专业计划和定制计划，每种计划都根据不同的需求和预算量身定制。'},
            {titel:'服务费用是多少？',content:'价格从基本计划的 50 美元/月到定制计划的 600 美元/月不等。详细定价请参阅我们的定价页面。'},
            {titel:'我以后可以更改计划吗？',content:'是的，您可以根据自己的需要随时升级或降级您的计划。推广和引用'},
            {titel:'需要多长时间才能看到效果？',content:'学术引用可能需要 6-8 个月或更长的时间才能反映在引用数据库中，但文章浏览量和社交媒体参与度等其他指标则可以更快地得到跟踪。'},
            {titel:'如何确保推广符合道德规范？',content:'我们严格遵守道德准则，避免垃圾邮件，确保我们的促销活动具有针对性和相关性。'},
            {titel:'我的文章一定能获得更多引用吗？',content:'虽然我们不能保证文章一定会被引用，但我们有针对性的推广策略会大大增加您的文章被合适受众关注和引用的可能性。隐私和数据安全'},
            {titel:'你们如何处理我的个人数据',content:'我们非常重视数据隐私，并遵循严格的安全协议来保护您的信息。详情请参见我们的隐私政策。'},
            {titel:'我的文章信息安全吗？',content:'是，所有提交到我们平台的文章信息都会被安全存储，并且仅用于提供我们的服务。'},
            {titel:'如何联系客户支持？',content:'如有任何问题或帮助，您可以通过 "联系我们 "页面或电子邮件 (contact@paperspotlight.com) 联系我们的支持团队。'},
            {titel:'你们提供试用期吗？',content:'是的，我们为新用户提供 7 天试用期，让他们体验我们的基本计划服务。'},
            {titel:'我可以介绍他人使用你们的服务吗？',content:'是的，我们有一个推荐计划，您可以通过推荐新用户使用我们的平台来赚取积分。'},
            {titel:'如果我对服务不满意怎么办？',content:'我们致力于让客户满意，如果您对我们的服务不满意，我们保证在最初 10 天内退款。'},
            {titel:'我可以要求定制推广策略吗？',content:'是的，我们提供定制计划，根据您的具体需求和目标量身定制推广策略。请发送申请电子邮件至： contact@paperspotlight.com'},
        ],
        
    },
    tableModule:{
        buyBtn:'订阅',
        // cheapBtn:'最划算',
        packageObj:[
            {title:'test隐藏',subTitle:'test隐藏',amount:'3',monthTotalAmount:'$3',monthAmount:'',discounts:'3',numOfPeople:'Number of people reached per month',analysis:'Analysis based on new articles in related fields',relevance:'Relevance Analysis and Citation Suggestions',email:'Email introductions are sent to the authors of the most recent relevant articles published',anonymous:'Anonymous referral service',exposure:'Exposure on social platforms in relevant fields',push:'Push to our reviewers and editors',open:'Open source code or dataset sharing',targeted:'Targeted recommendations to authors who are likely to cite your article in the near future'},
            {title:'标准',subTitle:'本科生, 研究生, 慢慢增加自己学术背景',amount:'6',monthTotalAmount:'',monthAmount:'120',discounts:'',numOfPeople:20,analysis:true,relevance:true,email:true,anonymous:true,exposure:false,push:false,open:false,targeted:false},
            {title:'标准+',subTitle:'PHD新生, 拓宽自己人脉及关系网络',amount:'3.75',monthTotalAmount:'',monthAmount:'300',discounts:'',numOfPeople:80,analysis:true,relevance:true,email:true,anonymous:true,exposure:true,push:false,open:false,targeted:false},
            {title:'专业',subTitle:'2年+PHD，准备申请NIW的博士生',amount:'2.81',monthTotalAmount:'',monthAmount:'450',discounts:'',numOfPeople:160,analysis:true,relevance:true,email:true,anonymous:true,exposure:true,push:true,open:false,targeted:false},
            {title:'专业+',subTitle:'准备申请EB1A的申请人',amount:'1.87',monthTotalAmount:'',monthAmount:'600',discounts:'',numOfPeople:320,analysis:true,relevance:true,email:true,anonymous:true,exposure:true,push:true,open:true,targeted:false},
            {title:'标准+',subTitle:'准备入职高校, 升职教授,以学术为职业生涯的人',amount:'1.5',monthTotalAmount:'',monthAmount:'900',discounts:'',numOfPeople:600,analysis:true,relevance:true,email:true,anonymous:true,exposure:true,push:true,open:true,targeted:true},
        ],
        packageObj2:[
            {title:'test隐藏',subTitle:'test隐藏',amount:'3',monthTotalAmount:'3',monthAmount:'',discounts:'3',numOfPeople:'Number of people reached per month',analysis:'Analysis based on new articles in related fields',relevance:'Relevance Analysis and Citation Suggestions',email:'Email introductions are sent to the authors of the most recent relevant articles published',anonymous:'Anonymous referral service',exposure:'Exposure on social platforms in relevant fields',push:'Push to our reviewers and editors',open:'Open source code or dataset sharing',targeted:'Targeted recommendations to authors who are likely to cite your article in the near future'},
            {title:'标准',subTitle:'本科生, 研究生, 慢慢增加自己学术背景',amount:'6.65',monthTotalAmount:'',monthAmount:'133',discounts:'',numOfPeople:20,analysis:true,relevance:true,email:true,anonymous:true,exposure:false,push:false,open:false,targeted:false},
            {title:'标准+',subTitle:'PHD新生, 拓宽自己人脉及关系网络',amount:'4.16',monthTotalAmount:'',monthAmount:'333',discounts:'',numOfPeople:80,analysis:true,relevance:true,email:true,anonymous:true,exposure:true,push:false,open:false,targeted:false},
            {title:'专业',subTitle:'2年+PHD，准备申请NIW的博士生',amount:'3.13',monthTotalAmount:'',monthAmount:'500',discounts:'',numOfPeople:160,analysis:true,relevance:true,email:true,anonymous:true,exposure:true,push:true,open:false,targeted:false},
            {title:'专业+',subTitle:'准备申请EB1A的申请人',amount:'2.08',monthTotalAmount:'',monthAmount:'666',discounts:'',numOfPeople:320,analysis:true,relevance:true,email:true,anonymous:true,exposure:true,push:true,open:true,targeted:false},
            {title:'标准+',subTitle:'准备入职高校, 升职教授,以学术为职业生涯的人',amount:'1.66',monthTotalAmount:'',monthAmount:'1000',discounts:'',numOfPeople:600,analysis:true,relevance:true,email:true,anonymous:true,exposure:true,push:true,open:true,targeted:true},
        ]
    },

    //弹框提示
    boxTip:{
        basicInfor1:'请输入正确的Google Scholar 个人网页',
        basicInfor2:'请输入正确的Google Scholar 个人网页并点击确认及分析按钮'
    }
    
}