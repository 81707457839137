<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-03-20 20:49:33
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-03-21 20:35:30
 * @FilePath: /chat_gpt/src/App.vue
-->
<template>
  <div id="app">
    <!-- <keep-alive >
      <router-view  v-if="$route.meta.keepAlive"/> 
    </keep-alive> -->
    <router-view /> 

  </div>
</template>
<script>
export default {
}
</script>
<style lang="scss">
body {
  margin: 0;
  padding: 0;
}
#app{
  width: 100vw;
  height: 100vh;
}
</style>
