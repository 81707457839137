/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 小白
 * @Date: 2022-04-25 15:58:33
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-03-28 14:50:28
 * @FilePath: /chat_gpt/src/api/api.js
 */
import axios from 'axios'

// export const baseUrl = 'http://47.88.24.100/paper-server'
export const baseUrl = 'https://paperspotlight.com/paper-server'

// 用户登录
// export const LOGIN = params => { return axios.post(`${baseUrl}/login`, params).then(res => res.data) }
// // 用户退出
// export const GETVERCODE = params => { return axios.get(`${baseUrl}/verifyCode`) }

//
export const HTTP_URI = {
  BASEURL: baseUrl,

  usetRegister: baseUrl +'/user/register',  //用户注册
  sendEmail: baseUrl +'/code/sendEmail',  //发送邮箱验证码
  changePassword: baseUrl +'/user/changePassword',  //修改密码
  forgetPassword: baseUrl +'/user/forgetPassword',  //忘记密码 修改密码
  cancelAccount: baseUrl +'/user/cancelAccount',  //注销

  usetrLogin: baseUrl +'/user/login',  //用户登录
  userlogout: baseUrl +'/user/logout',  //用户登出
  getUserInfo: baseUrl +'/user/getUserInfo',  //获取个人信息
  editUserInfo: baseUrl +'/user/editUserInfo',  //修改个人信息
  getUserScholarDetail: baseUrl +'/user/getScholarDetail',  //获取个人网页信息


  getOrderNumber: baseUrl +'/order/getOrderNumber',  //获取随机数 get

  
  getPayPackage: baseUrl +'/package/getPaymentPackageList',  //获取可选套餐列表

  addArticle: baseUrl +'/article/addArticle',  //新增文章
  editArticle: baseUrl +'/article/editArticle',  //修改文章
  deleteArticle: baseUrl +'/article/deleteArticle',  //删除文章
  getArticleList: baseUrl +'/article/getArticleList',  //获取文章列表
  uploadFile: baseUrl +'/article/uploadFile',  //上传

  addOrder: baseUrl +'/order/addOrder',  //新增订单 -套餐
  getUserPackageList: baseUrl +'/package/getUserPackageList',  //获取用户已经支付的套餐列表
  getArticlesByPackage: baseUrl +'/package/getArticlesByPackage',  //获取套餐已经添加的文章列表
  packageOfAddArticle: baseUrl +'/package/addArticle',  //添加文章到套餐
  packageOfremoveArticle: baseUrl +'/package/removeArticle',  //取消套餐文章
  refundPaymentOrder: baseUrl +'/order/refundPaymentOrder',  //单次购买的套餐退款：?paymentIntent=djfhajsdfas
  cancelSubscription: baseUrl +'/order/cancelSubscription',  //取消订阅
  refundInvoiceOrder: baseUrl +'/order/refundInvoiceOrder',  //订阅套餐单个订单退款?invoiceId=djfhajsdfas
  getOrderList: baseUrl +'/order/getOrderList',  //已经支付的订单列表：

  getMessageList: baseUrl +'/message/getMessageList',  //detail

  getNecessary: baseUrl +'/user/getNecessary',  //获取需要做的


  addContact: baseUrl +'/user/addContact',  //邮箱告诉我们
  addStrategy: baseUrl +'/user/addStrategy',  //个人喜好
  getStrategy: baseUrl +'/user/getStrategy',  //获取个人喜好

  unsubscribeEmail: baseUrl +'/code/unsubscribeEmail',  //退订接口



 


  

  // http://127.0.0.1:8811/order/cancelSubscription?userPackageId=djfhajsdfas 取消订阅







  UPLOAD: baseUrl +'/upload/uploadFile',
  ADDITEM: baseUrl +'/project/addProjectInfo',//新增项目数据
  EDITITEM: baseUrl +'/project/editEnterpriseInfo',//修改项目信息
  GETITEM: baseUrl +'/project/getAllProjectInfo',//获取所有项目列表
  DElITEM: baseUrl +'/project/deleteProjectById',//删除项目

  EDITPROJECT:baseUrl +'/project/editProjectInfo',  //修改项目信息
  GETDETIAL: baseUrl +'/project/getProjectById',//根据项目Id获取项目详情
  GETVERCODE: baseUrl + '/code/getVerify', //获取图片验证码
  EMAILCODE: baseUrl + '/code/sendEmail', //发送邮件验证码
  LOGIN: baseUrl + '/user/login', //用户登录
  REGISTER: baseUrl + '/user/register', //用户注册
  FORGETWORD: baseUrl + '/user/forgetPassword', //忘记密码
  LOGOUT: baseUrl + '/user/logout', //退出

  GETALLUSER: baseUrl + '/user/getAllUser', //获取所有用户列表
  CHANGEROLE: baseUrl + '/user/changeUserRole', //修改用户权限





  GETALLUSERLIST : baseUrl + '/manage/getUserRoom', //查询用户聊天列表
  GETONELIST : baseUrl + '/manage/getUserRoomById', //根据id查询用户聊天具体内容
  GETCODELIST : baseUrl + '/manage/getInviteCode', //查询邀请码列表"
  CREATECODE : baseUrl + '/manage/saveInviteCode', //生成邀请码"
  CODENOTES : baseUrl + '/manage/saveInviteCodeNotes', //邀请码备注

  DELCHAT: baseUrl + '/chatUser/deleteUserChat', //删除用户聊天记录
  GETUSERLIST: baseUrl +'/chatUser/getUserChatList',//获取用户聊天记录
  PRODUCT: baseUrl + '/product/list',
  USERHOME: baseUrl + '/user/token/home',
  CHAT: baseUrl + '/v1/chat/turbo',
  getType: baseUrl + '/user/token/getType',
  REPEST: baseUrl + '/use/log/updateLog',
  ADD: baseUrl + '/use/log/resetLog',
  NOTICELIST: baseUrl + '/announcement/queryPage',
  PAY: baseUrl + '/order/create'
}
// export default { HTTP_URI, baseUrl }
