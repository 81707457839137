import Vue from "vue";
import VueI18n from 'vue-i18n'
//引入自定义语言配置
import zh from './langs/zh'
import en from './langs/en'
//引入UI框架语言配置 --element ui
import ElementLocate from 'element-ui/lib/locale'
import zhlocale from 'element-ui/lib/locale/lang/zh-CN'
import enLocale from 'element-ui/lib/locale/lang/en'

ElementLocate.i18n((key, value) => i18n.t(key, value))  //为了实现element 插件的多语言切换
Vue.use(VueI18n)

//准备翻译的语言环境信息
const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'en', //将语言标识存入localStorage或 sessionStorage中，页面刷新不会默认中文显示
    messages: {
        //中文语言包
        'zh': {
            ...zh,
            ...zhlocale
        },
        //英文语言包
        'en': {
            ...en,
            ...enLocale
        }
    }
})

export default i18n

